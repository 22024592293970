import { createRouter, createWebHashHistory } from 'vue-router'
export const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login/login.vue')
  },
  {
    path: '/miniprogram/approvalWebview',
    name: 'MiniprogramApprovalWebview',
    component: () => import ('@/views/miniprogram/miniprogram.vue')
  },
  {
    path: '/personnelField/scanCodeForm',
    name: 'scanCodeForm',
    component: () => import('@/views/personnelField/scanCodeForm.vue')
  },
   {
    path: '/personnelField/scanCodeFormSuccessfully',
    name: 'scanCodeFormSuccessfully',
    component: () => import('@/views/personnelField/scanCodeFormSuccessfully.vue')
  },
  {
    path: '/staging',
    name: 'Staging',
    component: () => import('@/views/home/index.vue'),
    redirect: '/workbetch',
    children:[
      {
        path: '/workbetch',
        name: 'WorkBetch',
        component: () => import("@/views/home/workbetch.vue"),
      },
      {
        path: '/user/enterprise',
        name: 'UserEnterprise',
        component: () => import("@/views/user/enterprise.vue"),
      },
      {
        path: '/user/info',
        name: 'UserInfo',
        component: () => import("@/views/user/info.vue"),
      },
      {
        path: '/approval',
        name: 'Approval',
        component: () => import('@/views/wflow/admin/FormProcessDesigner.vue')
      },
      {
        path: '/management',
        name: 'Management',
        component: () => import("@/views/management/management.vue"),
        children: [
          {
            path: '/role/management',
            name: 'RoleManagement',
            component: () => import("@/views/management/role.vue")
          },
          {
            path: '/organizational/structure',
            name: 'OrganizationalStructure',
            component: () => import("@/views/organizational/structure.vue")
          },
          {
            path: '/user/management',
            name: 'UserManagement',
            component: () => import('@/views/user/management.vue')
          },
          {
            path: '/unit/management',
            name: 'UnitManagement',
            component: () => import('@/views/unit/management.vue')
          },
          {
            path: '/application/management',
            name: 'ApplicationManagement',
            component: () => import('@/views/application/management.vue')
          },
          {
            path: '/jobNumber/management',
            name: 'JobNumberManagement',
            component: () => import('@/views/jobNumber/management.vue')
          },
          {
            path: '/message/center',
            name: 'MessageCenter',
            component: () => import('@/views/message/center.vue')
          },
          {
            path: '/message/rules',
            name: 'MessageRules',
            component: () => import('@/views/message/rules.vue')
          },
          {
            path: '/message/test',
            name: 'MessageTest',
            component: () => import('@/views/message/test1.vue')
          },
          {
            path: '/notice/management',
            name: 'NoticeManagement',
            component: () => import('@/views/notice/management.vue')
          },
          {
            path: '/notice/received',
            name: 'NoticeReceived',
            component: () => import('@/views/notice/received.vue')
          },
          {
            path: '/personnel/assistant/home',
            name: 'PersonnelAssistantHome',
            component: () => import('@/views/personnel/home.vue')
          },
          // {
          //   path: '/personnel/assistant/roster',
          //   name: 'PersonnelAssistantRoster',
          //   component: () => import('@/views/personnel/roster.vue')
          // },
          {
            path: '/personnelField/personnelField',
            name: 'personnelField',
            component: () => import('@/views/personnelField/personnelField.vue')
          },
          {
            path: '/personnelField/systemSalaryRating',
            name: 'systemSalaryRating',
            component: () => import('@/views/personnelField/systemSalaryRating.vue')
          },
          {
            path: '/personnelField/roster',
            name: 'roster',
            component: () => import('@/views/personnelField/roster.vue'),
            meta:{
              keepAlive: true
            }
          },{
            path: '/personnelField/rosterDetail/:id',
            name: 'rosterDetail',
            component: () => import('@/views/personnelField/rosterDetail.vue')
          },
          {
            path: '/personnelField/upload',
            name: 'upload',
            component: () => import('@/views/personnelField/upload.vue')
          },
          {
            path: '/personnelField/addEmployInfo/:id',
            name: 'addEmployInfo',
            component: () => import('@/views/personnelField/addEmployInfo.vue')
          },
          // {
          //   path: '/personnel/assistant/addEmployInfo',
          //   name: 'PersonnelAssistantAddEmployInfo',
          //   component: () => import('@/views/personnel/addEmployInfo.vue')
          // },
          {
            path: '/personnel/assistant/enter',
            name: 'PersonnelAssistantEnter',
            component: () => import('@/views/personnel/enter.vue'),
            meta:{
              keepAlive: true
            }
          },
          {
            path: '/personnel/assistant/applyFull',
            name: 'PersonnelAssistantApplyFull',
            component: () => import('@/views/personnel/applyFull.vue')
          },
          {
            path: '/personnel/assistant/dismission',
            name: 'PersonnelAssistantDismission',
            component: () => import('@/views/personnel/dismission.vue')
          },
          {
            path: '/personnel/assistant/transaction',
            name: 'PersonnelAssistantTransaction',
            component: () => import('@/views/personnel/transaction.vue')
          },
          {
            path: '/personnel/assistant/roster_import',
            name: 'PersonnelAssistantRosterImport',
            component: () => import('@/views/personnel/roster_import.vue')
          },
          {
            path: '/personnel/assistant/roster_import_detail/:id',
            name: 'PersonnelAssistantRosterImportDetail',
            component: () => import('@/views/personnel/importFileDetail.vue')
          },
          {
            path: '/personnel/assistant/quit_user_list',
            name: 'PersonnelAssistantQuitUserList',
            component: () => import('@/views/personnel/quit_user_list.vue')
          },
          {
            path: '/personnel/assistant/contract_ledger',
            name: 'PersonnelAssistantContractLedger',
            component: () => import('@/views/personnel/contract_ledger.vue')
          },
          // {
          //   path: '/personnel/assistant/detail/:id',
          //   name: 'PersonnelAssistantDetail',
          //   component: () => import('@/views/personnel/detail.vue')
          // },
          // {
          //   path: '/personnel/assistant/upload',
          //   name: 'PersonnelAssistantUpload',
          //   component: () => import('@/views/personnel/upload.vue')
          // },
          {
            path: '/account/association',
            name: 'AccountAssociation',
            component: () => import('@/views/enterpriseWechat/memoformat.vue')
          },
          {
            path: '/approval/process/management',
            name: 'ApprovalProcessManagement',
            component: () => import('@/views/approval/processManagement.vue')
          },
          {
            path: '/approval/ccmy',
            name: 'ApprovalCcmy',
            component: () => import('@/views/approval/ccmy.vue')
          },
          {
            path: '/approval/list',
            name: 'ApprovalList',
            component: () => import('@/views/approval/list.vue')
          },
          {
            path: '/approval/waiting/me',
            name: 'ApprovalWaitingMe',
            component: () => import('@/views/approval/waitingMe.vue')
          },
          {
            path: '/approval/processed',
            name: 'ApprovalProcessed',
            component: () => import('@/views/approval/processed.vue')
          },
          {
            path: '/approval/initated',
            name: 'ApprovalInitated',
            component: () => import('@/views/approval/initated.vue')
          },
          {
            path: '/approval/model',
            name: 'ApprovalModel',
            component: () => import('@/views/approval/model.vue')
          },
          {
            path: '/approval/role',
            name: 'ApprovalRole',
            component: () => import('@/views/approval/role.vue')
          },
          {
            path: '/attendance/class/management',
            name: 'AttendanceClassManagement',
            component: () => import('@/views/attendance/class/management.vue')
          },
          {
            path: '/attendance/rules',
            name: 'AttendanceRules',
            component: () => import('@/views/attendance/holidays/rules.vue')
          },
          {
            path: '/attendance/team',
            name: 'AttendanceTeam',
            component: () => import('@/views/attendance/team/team.vue')
          },
          {
            path: '/attendance/schedule/fixed',
            name: 'AttendanceScheduleFixed',
            component: () => import('@/views/attendance/schedule/fixed.vue')
          },
          {
            path: '/attendance/schedule/unFixed',
            name: 'AttendanceScheduleUnFixed',
            component: () => import('@/views/attendance/schedule/unFixed.vue')
          },
          {
            path: '/attendance/report',
            name: 'AttendanceReport',
            component: () => import('@/views/attendance/report/report.vue'),
          },
          {
            path: '/attendance/user/scheduling',
            name: 'AttendanceUserScheduling',
            component: () => import('@/views/attendance/user/scheduling.vue'),
          },
          {
            path: '/attendance/abnormal',
            name: 'AttendanceAbnormal',
            component: () => import('@/views/attendance/abnormal/abnormal.vue'),
          },
          {
            path: '/attendance/mine',
            name: 'AttendanceMine',
            component: () => import('@/views/attendance/mine/mine.vue'),
          },
          {
            path: '/attendance/statistics',
            name: 'AttendanceStatistics',
            component: () => import('@/views/attendance/statistics/statistics.vue'),
          },
          {
            path: '/:catchAll(.*)',
            name: 'NotFound',
            component: () => import('@/views/not/found.vue')
          },
          {
            path: '/salaryCalculation/employeeCompensation',
            name: 'employeeCompensation',
            component: () => import('@/views/salaryCalculation/employeeCompensation.vue')
          },
          {
            path: '/salaryCalculation/employeeCompensationDetail/:id',
            name: 'employeeCompensationDetail',
            component: () => import('@/views/salaryCalculation/employeeCompensationDetail.vue')
          },
          {
            path: '/salaryCalculation/payrollGeneration',
            name: 'payrollGeneration',
            component: () => import('@/views/salaryCalculation/payrollGeneration.vue')
          },
          {
            path: '/salaryCalculation/payrollGenerationDetail/:id',
            name: 'payrollGenerationDetail',
            component: () => import('@/views/salaryCalculation/payrollGenerationDetail.vue')
          },
          {
            path: '/salaryCalculation/SalaryCompositionImport',
            name: 'SalaryCompositionImport',
            component: () => import('@/views/salaryCalculation/SalaryCompositionImport.vue')
          },
          {
            path: '/WorkforceManagement/SchedulingRecord',
            name: 'SchedulingRecord',
            component: () => import('@/views/WorkforceManagement/SchedulingRecord.vue')
          },
          {
            path: '/AssessmentManagement/AssessmentRecords',
            name: 'AssessmentRecords',
            component: () => import('@/views/AssessmentManagement/AssessmentRecords.vue')
          },
          
        ]
      },
    ]
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token');
  if (token) {
    if (to.path == '/login' || to.path == '/') {
      next('/staging')
    } else {
      next()
    }
  } else {
    if (to.path == '/login') {
      next();
    } else {
      if(to.path == '/personnelField/scanCodeForm' || to.path == '/miniprogram/approvalWebview' || to.path == '/personnelField/scanCodeFormSuccessfully'){
        next();
      }else{
        next('/login');
      }
       
    }
  }
});
export default router
